import type { PriceTier } from '@b2b-platform/commerce/types/price';

export const formatPrice = (price: number) =>
    `€ ${new Intl.NumberFormat('en-DE').format(price / 100)}`;

export const getPriceFromPriceTiers = (
    tiers: PriceTier[] | null,
    quantity,
): Price | null =>
    tiers?.find(
        (priceTier) =>
            quantity >= priceTier.minimumQuantity &&
            quantity <= priceTier.maximumQuantity,
    )?.price;
